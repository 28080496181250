import {
  Badge,
  Button,
  Card,
  Col, Collapse,
  Container,
  Dropdown,
  DropdownButton,
  FloatingLabel,
  Form,
  Row
} from "react-bootstrap"
import { useCallback, useEffect, useRef, useState } from "react"
import CardHeader from "react-bootstrap/CardHeader"
import axios from "axios"
import { inIframe } from "./utils"

const api = axios.create({
  headers: {
    'x-api-key': 'uiXq2Dxe6l1szxzHA2ozeRpvQlvxqtm2BGkDdrf8'
  }
})

function BookCard(props) {
  const { book } = props
  const lendUrl = `https://docs.google.com/forms/d/e/1FAIpQLSdQ09UANbZOFGWydBkDD8yv35c3nxdhMmOoS8osYwwxx-2G-w/viewform?usp=pp_url&entry.564302444=${book.num}&entry.265206261=${encodeURIComponent(book.title)}`

  return <Card className="h-100">
    <CardHeader>
      <div><strong>{book.num}</strong></div>
      <div><strong>{book.title}</strong></div>
    </CardHeader>
    <Card.Body>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div>著者：{book.author}</div>
          <br/>
          <div>{book.publisher}</div>
          <div className='book-edition'>({book.edition})</div>
        </div>
        <div>
          <div>{book.open_lend_records_count > 0 ?
            <Badge bg='secondary'>已借出</Badge> :
            <div className='d-flex justify-content-between align-items-center'>
              <div><Badge bg='success'>櫃架上</Badge></div>
              <a href={lendUrl} target="_blank" className='btn btn-outline-primary'>立即借閱</a>
            </div>}
          </div>
        </div>
      </div>
    </Card.Body>
    <Card.Footer>
      <div className="book-category">{book.category.title}</div>
    </Card.Footer>
  </Card>
}

function Pagination(props) {
  const { total, page, onPageChanged } = props;
  const totalPage = Math.ceil(total / 48)

  function handlePageChanged(pageNum) {
    if (onPageChanged)
      onPageChanged(pageNum)
  }

  function renderOptions() {
    const options = []
    for (let i = 0; i < totalPage; i++) {
      options.push(<option key={`page-${i + 1}`} value={i + 1}>{i + 1}</option>)
    }
    return options;
  }

  return <div className='d-flex'>
    <Button size="sm" disabled={page === 1} onClick={() => handlePageChanged(page - 1)}>上一頁</Button>
    <div className='d-flex px-2'>
      <select defaultValue={page} onChange={(e) => handlePageChanged(e.target.value)}>{renderOptions()}</select>
      <span className='ps-1'>/{totalPage}頁</span>
    </div>
    <Button size="sm" disabled={page === totalPage} onClick={() => handlePageChanged(page + 1)}>下一頁</Button>
  </div>
}

function CategorySelect(props) {
  const { value, onChange } = props;
  const [categories, setCategories] = useState([]);

  async function apiGetCategories() {
    const response = await api.get(`/api/categories`);
    const categories = response.data
    setCategories(categories)
  }

  function renderCategoryOptions() {
    const options = [];

    options.push(<Dropdown.Item key={`cat-default`} eventKey={'default'} className="w-100 category-option"
                                onClick={() => onChange(null)}>
      <div className={`ps-0 d-flex`}>
        <div style={{ width: "70vw", minWidth: 300, maxWidth: 350, whiteSpace: 'normal' }}>--選擇分類--</div>
      </div>
    </Dropdown.Item>)
    function generateOptions(list, layer = 0) {
      list.forEach((category) => {
        options.push(<Dropdown.Item key={`cat-${category.id}`} eventKey={category.id} className="w-100 category-option"
                                    onClick={() => onChange(category.id)}>
          <div className={`ps-${layer * 2} d-flex`}>
            <div className="pe-1">{category.num} </div>
            <div style={{ width: "70vw", minWidth: 300, maxWidth: 350, whiteSpace: 'normal' }}>{category.title}</div>
          </div>
        </Dropdown.Item>)
        if (category.children) {
          generateOptions(category.children, layer + 1)
        }
      })
    }

    generateOptions(categories)

    return options;
  }

  const getDropdownTitle = useCallback(() => {
    function flatArray(array) {
      return array.reduce((current, item) => {
        const { children, ...rest } = item;
        return current.concat(rest).concat(children ? flatArray(children) : [])
      }, [])
    }

    const flattenCategories = flatArray(categories);
    const cat = flattenCategories.find((cat) => cat.id === value)
    if (cat)
      return `${cat.num} ${cat.title}`
    return null;
  }, [value])

  useEffect(() => {
    apiGetCategories();
  }, [])

  let dropdownTitle = "--選擇分類--"
  if (value) {
    const newTitle = getDropdownTitle()
    if (newTitle)
      dropdownTitle = newTitle;
  }

  if (categories.length === 0)
    return null;

  return <Row className="mt-2 mb-2 align-items-center">
    <Col>
      <div className="d-flex align-items-center">
        <Form.Label htmlFor="category" className="text-white">分類：</Form.Label>
        <DropdownButton title={dropdownTitle} className="btn-category" variant="info">
          {renderCategoryOptions()}
        </DropdownButton>
      </div>
    </Col>
  </Row>
}

export default function BookSearch() {
  const searchRef = useRef()
  const [loading, setLoading] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchNum, setSearchNum] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchAuthor, setSearchAuthor] = useState("");
  const [books, setBooks] = useState([]);
  const [category, setCategory] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  async function handleSubmit(e) {
    e.preventDefault();
    await apiGetBooks({
      searchNum: searchNum.trim(),
      searchText: searchText.trim(),
      searchName: searchName.trim(),
      searchAuthor: searchAuthor.trim()
    })
  }

  async function handlePageChanged(pageNum) {
    await apiGetBooks({
      searchNum: searchNum.trim(),
      searchText: searchText.trim(),
      searchName: searchName.trim(),
      searchAuthor: searchAuthor.trim(),
      pageNum
    })
  }

  async function handleClearClick() {
    setCategory("");
    setSearchText("")
    setSearchNum("")
    setSearchName("")
    setSearchAuthor("")
    searchRef.current.reset();
  }

  async function apiGetBooks(params) {
    setLoading(true)
    const { searchNum, searchName, searchAuthor, searchText, pageNum } = params ?? {};
    setPage(pageNum ?? 1);
    const queryParams = [];
    if (searchText)
      queryParams.push(`searchText=${encodeURIComponent(searchText.toString().trim())}`)
    if (searchNum)
      queryParams.push(`searchNum=${encodeURIComponent(searchNum)}`)
    if (searchName)
      queryParams.push(`searchName=${encodeURIComponent(searchName)}`)
    if (searchAuthor)
      queryParams.push(`searchAuthor=${encodeURIComponent(searchAuthor)}`)
    if (pageNum)
      queryParams.push(`pageNum=${encodeURIComponent(pageNum.toString().trim())}`)
    if (category)
      queryParams.push(`category=${encodeURIComponent(category.toString().trim())}`)
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    const response = await api.get(`/api/books${queryString}`);
    const { count, books } = response.data
    setCount(count)
    setBooks(books)
    setLoading(false)
  }

  const renderBooks = useCallback(() => {
    return <Row>
      {books.map((book) =>
        <Col key={`book-${book.num}`} lg={3} md={4} className="my-3">
          <BookCard book={book}/>
        </Col>)
      }
    </Row>
  }, [books])

  useEffect(() => {
    apiGetBooks();
  }, [])

  return <div className="pb-4">
    <Container fluid={true} className="jumbotron">
      <div className="book-search-container">
        {!inIframe() && <>
          <div className="text-center">
            <img alt="九龍塘基督教中華宣道會屯門友愛堂" src="/logo_white.png" className="img-fluid logo"/>
          </div>
        </>}
        <div>
          <Button
            variant="secondary"
            className="w-100 mb-2 btn-search d-flex justify-content-center align-items-center"
            size="sm"
            onClick={() => setSearchOpen(!searchOpen)}
            aria-controls="collapse-search-form"
            aria-expanded={searchOpen}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 className="bi bi-search" viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
            <span className="ms-1">搜尋圖書</span>
          </Button>
        </div>
        <Collapse in={searchOpen}>
          <div id="collapse-search-form">
            <Form className="book-search-form" onSubmit={handleSubmit} ref={searchRef}>
              <CategorySelect value={category} onChange={(catId) => {
                setCategory(catId);
              }}/>
              {/*<Form.Control placeholder="圖書名稱 / 著者 / 出版" onChange={(e) => setSearchText(e.target.value)}/>*/}
              <FloatingLabel
                controlId="floating-label-num-search"
                label="索書號"
                className="mb-3"
              >
                <Form.Control placeholder="索書號" size="sm" onChange={(e) => setSearchNum(e.target.value)}/>
              </FloatingLabel>
              <FloatingLabel
                controlId="floating-label-name-search"
                label="圖書名稱"
                className="mb-3"
              >
                <Form.Control placeholder="圖書名稱" size="sm" onChange={(e) => setSearchName(e.target.value)}/>
              </FloatingLabel>
              <FloatingLabel
                controlId="floating-label-author-search"
                label="著者"
                className="mb-3"
              >
                <Form.Control placeholder="著者" size="sm" onChange={(e) => setSearchAuthor(e.target.value)}/>
              </FloatingLabel>
              <div className="text-center">
                <Button type="submit">搜尋</Button>
                <Button variant="outline-warning" className="ms-2" onClick={handleClearClick}>清除</Button>
              </div>
            </Form>
          </div>
        </Collapse>
      </div>
    </Container>
    <Container>
      {loading ?
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
          <div className="spinner-border" role="status"/>
        </div> :
        <div className="mt-2">
          <div>共有 {count} 本</div>
          {count > 0 && <>
            <div>
              <Pagination total={count} page={page} onPageChanged={handlePageChanged}/>
            </div>
            <div>{renderBooks()}</div>
            <div>
              <Pagination total={count} page={page} onPageChanged={handlePageChanged}/>
            </div>
          </>
          }
        </div>
      }
    </Container>
    <Container className="mt-4">
      <div className="text-center" style={{ color: '#888' }}>
        <small>©{new Date().getFullYear()} 中華宣道會友愛堂圖書部</small>
      </div>
    </Container>
  </div>
}
